* {
  box-sizing: border-box;
}

body {
  background: #f2f2f2;
  color: #555555;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  padding: 0;
  margin: 0;
}

div {
  padding: 0;
  margin: 0;
}

.net-connectivity-test-page {
  text-align: center;
  max-width: 768px;
  margin: 0 auto;

  .test-status-icon {
    height: 24px;
  }

  .logo-header-section {
    margin: 0 auto;
    padding: 20px 0 0 0;
  }

  .button {
    display: inline-block;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    letter-spacing: 0.8px;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    width: fit-content;
  }

  .btn-red {
    background: #fa4d46;
    border: 1px solid #fa4d46;
    color: #ffffff;

    &:hover {
      background: #f19791;
      border: 1px solid #f19791;
    }
  }

  .btn-red-disabled {
    width: 94px !important;
    height: 38px !important;
    padding: 0 !important;
    background: #f19791;
    border: 1px solid #f19791;
    color: #ffffff;

    .test-runing-logo {
      display: block;
      width: 21px;
      margin: 5.6px auto 0 auto;
    }

    #spinLogo {
      animation-name: spin;
      animation-duration: 3000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  .btn-transparent {
    border: 1px solid #1e1e1e;
    color: #555555;

    &:hover {
      color: #fa4d46;
    }
  }

  .logo-header-section,
  .main-section {
    max-width: 1024px;
  }

  .main-section {
    margin: 48px auto 0 auto;

    .run-test-info-section {
      margin: auto;
      max-width: 490px;
      .run-test-info-section-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        h1 {
          padding: 4px;
        }
      }
    }

    .red {
      color: red;
    }

    .run-test-info {
      .run-test-text-one,
      .run-test-text-two {
        font-size: 0.875rem;
        line-height: 1rem;
        margin: 0 0 16px 0;
      }

      .run-test-text-one {
        font-weight: 400;
      }

      .run-test-text-two {
        font-weight: 600;
      }
    }

    h1 {
      margin: 0 0 16px 0;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.75rem;
      color: #1b1b1b;
    }

    .printers-domains,
    .cloudprint-domains {
      width: 100%;
      max-width: 368px;
      h1 {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1rem;
      }
    }

    .test-section {
      text-align: left;
      width: 100%;
      margin: 48px 0 48px 0;
    }

    .printers-domains {
      margin: 0 50px 0 0;
    }

    .run-test-section {
      margin: 0 auto;
      max-width: 490px;

      div {
        margin: 0 20px 0 0;
      }

      .last-update-info {
        font-size: 0.75rem;
        line-height: 0.875rem;
        font-weight: 400;
        color: #555555;
      }

      .run-test-buttons {
        margin: 0 auto 16px auto;
      }
    }
  }

  .domain-checklist {
    width: 100%;
    .test-status-title {
      margin: 0 0 0 20px;
      text-transform: capitalize;
      margin-left: auto;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1rem;
    }

    .test-domain {
      margin: 0 0 0 10px;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1rem;
    }

    .domain-checklist-item {
      width: 100%;
      border-bottom: 1px solid #bbbbbb;
      padding: 29px 8px 12px 9px;
      display: flex;
      align-items: center;
    }
  }

  .left-section {
    width: 64%;
    padding: 0 3% 0 3%;
  }

  .test-flow-section {
    width: 100%;
    border: 1px solid #bbbbbb;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 0 0 16px 0;

    .test-title {
      font-size: 0.875rem;
      line-height: 1rem;
      display: flex;
      align-items: center;

      .test-text {
        margin: 0 0 0 18px;
        padding-right: 10px;
      }

      .test-status-label {
        margin-left: auto;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }

    .user-instraction {
      margin: 0 0 16px 0;

      p,
      h1 {
        margin: 0;
        padding: 0;
      }

      h1 {
        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 700;
        margin: 0 0 8px 0;
      }
    }

    .fail-domain-list,
    .not-supported-browser {
      font-size: 0.875rem;
      line-height: 1rem;
      padding: 0 40px;
      margin: 20px 0 0 0;
    }

    .fail-domain-item {
      display: flex;
      align-items: center;
      padding: 13px 9px;
      border-bottom: 1px solid #d9d9d9;

      .fail-domain {
        margin: 0 0 0 8px;
      }

      .fail-domain-icon {
        height: 24px;
      }
    }
  }

  .log-output-section {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    width: 30%;

    .log-output-title {
      padding: 20px 0 0px 24px;
      margin: 0 0 24px 0;

      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1rem;
      color: #555555;
    }

    .log-result-item,
    .finish-test-title,
    .start-test-title {
      font-family: "Source Code Pro", monospace;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1.313rem;
      padding: 0.625rem 0 0.625rem 1.5rem;
      border-bottom: 1px solid #f2f2f2;
    }
  }
}

.tooltip {
  display: block;
  margin-left: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 12px;
  word-wrap: break-word;
}

.tooltip-inner {
  max-width: 320px;
  padding: 8px 12px 8px 12px;
  color: #fff;
  text-align: center;
  background-color: #1b1b1b;
  border-radius: 0.25rem;
}

.arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
